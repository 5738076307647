
import Vue from 'vue';
import analytics from '../../mixins/analytics';
import statusCheck from '../../mixins/statusCheck';
import HeaderLogoC360 from '../components/toolbar/headerLogoC360.vue';
import { C360Provider, defineAbilityFor } from '@c360/ui';
import utils from '../../util';

export default Vue.extend({
  components: {
    HeaderLogoC360,
    AppGlobals: () => import('../global/globals.vue'),
    C360Footer: () => import('../global/footerC360.vue'),
    C360Provider,
  },
  data(): {
    error: string | undefined;
    loading: boolean;
    tokenLoading: boolean;
    errorMessage: string | null;
    emailLink: string;
    refreshToken: string;
  } {
    return {
      error: '',
      loading: false,
      tokenLoading: false,
      errorMessage: null,
      emailLink: 'mailto: support@sbganalytics.com',
      refreshToken: '',
    };
  },
  mixins: [statusCheck, analytics],
  computed: {
    accessToken() {
      return localStorage.getItem('ah:accessToken');
    },
    refreshTokenLS() {
      return localStorage.getItem('ah:refreshToken');
    },
    ability() {
      return defineAbilityFor({
        isSuperUser: this.$store.state?.customer?.user?.is_superuser ?? false,
        isAgencyAdmin: utils.hasRight(this.$store.state?.customer?.user, ['AGENCY_ADMIN']) ?? false,
        products: this.$store.state?.customer?.user?.products ?? [],
        permissions: this.$store.state?.customer?.user?.permissions ?? [],
        activeAgencyName: this.$store.state?.customer?.user?.active_agency_id ?? '',
        tenantsCount: this.$store.state?.customer?.user?.AvailableAgencies?.length ?? 1,
      });
    },
    c360Path(): string {
      return `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`;
    },
    backgroundColor(): object {
      return { 'background-color': 'var(--c360-background-primary)' };
    },
  },
});
